export default togglerClass => {
  const toggler = $('.' + togglerClass)

  let disableResetByFocusOut = true
  toggler.click(function(e) {
    e.preventDefault()
    const target = $('#' + $(this).attr('data-target'))
    if (!target.length) {
      return false
    }
    disableResetByFocusOut = typeof $(this).attr('data-focus') === 'undefined'

    target.toggleClass('is-active')

    if (disableResetByFocusOut) {
      toggler.focusout(function(e) {
        if ($(this).hasClass('is-active')) {
          e.preventDefault()
          const target = $('#' + $(this).attr('data-target'))
          toggler.removeClass('is-active')
          target.removeClass('is-active')
        }
      })
    }
  })
}
