export default () => {
  const btn = document.querySelector('.show-password-icon')
  const input = document.getElementById('password-auth')

  btn.addEventListener('click', () => {
    btn.classList.toggle('active')
    if (input.type === 'password') {
      input.type = 'text'
    } else {
      input.type = 'password'
    }
  })
}
