/* eslint-disable no-unused-vars */
export default () => {
  let priceModifier = {
    'global': 0,
    'pkg1': {
      mo: 0,
      year: 0
    },
    'pkg2': {
      mo: 0,
      year: 0
    },
    'pkg3': {
      mo: 0,
      year: 0
    }
  }
  let addonsCount = 0
  const slider = $('#slider')
  const plans = $('#js-pricing-plans')
  const modifiers = $('#js-pricing-addons .js-modifier')
  if (plans.length < 1 || slider.length < 1) {
    return false
  }

  const init = (noUiSlider, getPrices) => {
    const planBtns = $('.js-plan-btn')
    const planSwither = $('.plan-switch')
    const valuesCount = $('.pricing__count')
    const planBlock = $('.p-plan')
    const addonsPerBlock = $('.p-plan__addons')
    const extCounters = $('.js-ext-counter')
    const activeAddonsCounterBlock = $('.js-addons-counter-block')
    const activeAddonsCounter = $('.js-addons-count')
    const activeAddonsPrice = $('.js-addons-price')
    const activeAddonsPricePerUser = $('.js-user-addons-price')
    const addonsPricePerUser = document.querySelectorAll('.js-addon-price')
    const extUsersCountEl = $('.js-ext-users-count')
    const pkg1Rate = $('#js-pkg1-rate')
    const pkg2Rate = $('#js-pkg2-rate')
    const pkg3Rate = $('#js-pkg3-rate')

    const pkg1ExtRate = $('#js-pkg1-ext-rate')
    const pkg2ExtRate = $('#js-pkg2-ext-rate')
    const pkg3ExtRate = $('#js-pkg3-ext-rate')

    const pkg1Econ = $('#js-pkg1-econ')
    const pkg2Econ = $('#js-pkg2-econ')
    const pkg3Econ = $('#js-pkg3-econ')

    const pkg1UserEcon = $('#js-pkg1-user-econ')
    const pkg2UserEcon = $('#js-pkg2-user-econ')
    const pkg3UserEcon = $('#js-pkg3-user-econ')
    const inputNumber = $('.pricing__count-input')
    const usersCountText = $('.p-plan__header-saving-count')

    let usersCount = 5
    let planType = 'yearly'

    const formatPrice = priceVal => {
      let minimumFractionDigits = parseFloat(priceVal) - parseInt(priceVal) ? 2 : 0
      return parseFloat(priceVal).toLocaleString('nl-NL', {
        minimumFractionDigits: minimumFractionDigits
      })
    }

    const setValueFromHash = () => {
      const hashValue = window.location.hash.substr(1).split('=')

      return hashValue.length === 2 && hashValue[0] === 'user-count' && parseInt(hashValue[1])
          ? parseInt(hashValue[1])
          : 5
    }

    const scrollToPrice = () => {
      const chiefCover = $('#js-chief-cover')
      const winHeight = $(window).height()
      const elOffset = chiefCover.offset().top
      const elHeight = chiefCover.outerHeight()
      const targetPosition = elOffset - winHeight + elHeight - 20
      const priceVisible = window.pageYOffset + $(window).height() > chiefCover.offset().top + chiefCover.outerHeight()

      if (targetPosition && !priceVisible) {
        $([document.documentElement, document.body]).animate(
            {
              scrollTop: elOffset - winHeight + elHeight - 20
            },
            800
        )
      }
    }

    const changeLabels = () => {
      const el = $('.js-plan-label')
      const addons = document.querySelectorAll('.js-addon-desc')
      if ($('.js-plan-label.is-active').attr('data-type') !== planType) {
        el.toggleClass('is-active')
      }
      el.parent().attr('data-type', planType)

      // Plan Switcher Change Current Type
      planSwither.attr('data-current-type', planType)

      addons.forEach((el) => {
        if (planType === 'yearly') {
          el.textContent = el.dataset.descYear
        } else {
          el.textContent = el.dataset.descMonth
        }
      })
    }

    const checkPriceModifier = () => {
      priceModifier.global = 0
      priceModifier.pkg1.mo = 0
      priceModifier.pkg2.mo = 0
      priceModifier.pkg3.mo = 0
      priceModifier.pkg1.year = 0
      priceModifier.pkg2.year = 0
      priceModifier.pkg3.year = 0
      addonsCount = 0

      addonsPerBlock
          .find('.p-plan__addons-label_active')
          .each((index, el) => {
            const currentModVal = parseFloat(el.dataset.priceMonth)
            const currentModValYear = parseFloat(el.dataset.priceYear)
            const onetimePayment = Boolean(el.dataset.onetimePayment)

            priceModifier.global += currentModVal
            priceModifier[el.dataset.pkg]['mo'] += currentModVal * (!onetimePayment ? usersCount : 1)
            priceModifier[el.dataset.pkg]['year'] += currentModValYear * (!onetimePayment ? usersCount : 1)
            addonsCount += 1
          })

      // modifiers.each((index, el) => {
      //   const currEl = $(el);
      //   if (currEl.hasClass('is-active')) {
      //     const modifier = currEl.find('.js-addon-price');
      //     const currentModVal = parseFloat(modifier.text());
      //     priceModifier += currentModVal;
      //     addonsCount += 1;
      //   }
      // });
      // if (activeAddonsCounter.length) {
      //   activeAddonsCounter.text(addonsCount);
      // }
      // activeAddonsPrice.text(priceModifier * usersCount);
      // activeAddonsPricePerUser.text(priceModifier);
      //
      // if (addonsCount > 0) {
      //   activeAddonsCounterBlock.removeClass('is-hidden');
      // } else {
      //   activeAddonsCounterBlock.addClass('is-hidden');
      // }
    }

    const updatePrices = prices => {
      checkPriceModifier()

      // if (usersCount > 100) {
      //   $('.js-calculated').hide();
      //   $('.js-nocalculated').show();
      //   planBtns.addClass('is-invisible');
      //   extCounters.addClass('is-invisible');
      // } else {
      $('.js-nocalculated').hide()
      $('.js-calculated').show()
      planBtns.removeClass('is-invisible')
      extCounters.removeClass('is-invisible')
      // }

      extUsersCountEl.text((usersCount - 5))
      usersCountText.text((usersCount))
      pkg1Rate.text(formatPrice(prices.pkg1[planType].rate))
      pkg2Rate.text(formatPrice(prices.pkg2[planType].rate))
      pkg3Rate.text(formatPrice(prices.pkg3[planType].rate))

      pkg1ExtRate.text(formatPrice(prices.pkg1[planType].ext))
      pkg2ExtRate.text(formatPrice(prices.pkg2[planType].ext))
      pkg3ExtRate.text(formatPrice(prices.pkg3[planType].ext))

      pkg1Econ.text(formatPrice(prices.pkg1.yearly.economy))
      pkg2Econ.text(formatPrice(prices.pkg2.yearly.economy))
      pkg3Econ.text(formatPrice(prices.pkg3.yearly.economy))

      pkg1UserEcon.text(formatPrice(prices.pkg1.yearly.economy))
      pkg2UserEcon.text(formatPrice(prices.pkg2.yearly.economy))
      pkg3UserEcon.text(formatPrice(prices.pkg3.yearly.economy))
    }

    // const calcPriceModifier = () => {
    //   modifiers.click(ev => {
    //     const target = $(ev.currentTarget);
    //     target.toggleClass('is-active');
    //     checkPriceModifier();
    //     updatePrices(getPrices(usersCount, priceModifier));
    //   });
    // };
    //
    // calcPriceModifier();

    const changeAddonsPrices = () => {
      addonsPricePerUser.forEach(el => {
        if (planType === 'yearly') {
          el.textContent = el.dataset.priceYear
        } else {
          el.textContent = el.dataset.priceMonth
        }
      })
    }

    const changePlan = () => {
      updatePrices(getPrices(usersCount, priceModifier))
      changeLabels()
      changeAddonsPrices()
      plans.toggleClass('plan_monthly', planType === 'monthly')
    }

    $('#slider').click(ev => {
      $(ev.currentTarget).addClass('is-active')
      // scrollToPrice();
    })

    planBtns.click(ev => {
      const activeLabelType = $(ev.currentTarget)
          .find('.is-active')
          .attr('data-type')
      if (activeLabelType === 'monthly') {
        planType = 'yearly'
        changePlan()
      }
      // scrollToPrice();
    })

    // Plan Switcher
    if (planSwither.length) {
      const toggleCurrentPlan = () => {
        planType = planType === 'monthly' ? 'yearly' : 'monthly'

        changePlan()
      }

      const changeCurrentPlan = (e) => {
        const selectedPlan = e.currentTarget.dataset.type

        e.preventDefault()

        if (planType !== selectedPlan) {
          planType = selectedPlan

          changePlan()
        }
      }

      const switchToYearly = () => {
        if (planType === 'monthly') {
          toggleCurrentPlan()
        }
      }

      planSwither.on('click', '.plan-switch__btn', changeCurrentPlan)
      planSwither.on('click', '.plan-switch__trigger', toggleCurrentPlan)
      plans.on('click', '.p-plan__header-saving-text', switchToYearly)
    }

    const updatePips = value => {
      switch (value) {
        case 1:
          value = 5
          break
        case 600:
          value = '500+'
      }
      return value
    }

    const slider = document.getElementById('slider')
    noUiSlider.create(slider, {
      start: setValueFromHash(),
      step: 1,
      connect: [true, false],
      range: {
        min: 5,
        '20%': 25,
        '40%': 50,
        '60%': 100,
        '80%': 250,
        max: 600
      },
      pips: {
        mode: 'range',
        density: 20,
        values: 5,
        format: { to: updatePips }
      }
    })

    slider.noUiSlider.on('update', function(values, handle) {
      usersCount = parseInt(values[handle], 10)
      inputNumber.val(usersCount)
      // updatePrices(getPrices(usersCount, priceModifier));
      setTimeout(() => {
        updatePrices(getPrices(usersCount, priceModifier))
        history.replaceState(null, null, `#user-count=${ usersCount }`)
      }, 200)
    })

    // Change value on popstate
    $(window).on('popstate', () => {
      slider.noUiSlider.set([setValueFromHash()])
    })

    const pips = slider.querySelectorAll('.noUi-value')

    const clickOnPip = ev => {
      const value = Number(ev.currentTarget.getAttribute('data-value'))
      slider.noUiSlider.set([value])
    }

    for (let i = 0; i < pips.length; i++) {
      pips[i].style.cursor = 'pointer'
      pips[i].addEventListener('click', clickOnPip, { passive: true })
    }

    $.each(inputNumber, (index, item) => {
      item.addEventListener('change', () => {
        slider.noUiSlider.set([item.value])
      }, {
        passive: true
      })
    })

    // Change values by click
    if (valuesCount.length) {
      const changeInputValue = (e) => {
        const { currentTarget } = e
        const isDecrement = currentTarget.dataset.action === 'less'
        let currentValue = parseInt(slider.noUiSlider.get())
        const nextVal = isDecrement
            ? --currentValue
            : ++currentValue

        slider.noUiSlider.set([nextVal])
      }

      valuesCount.on('click', '.pricing__count-btn', changeInputValue)
    }

    // Toggle Feature Description
    // if (planBlock.length) {
    //   const toggleDescription = (e) => {
    //     const { currentTarget } = e;
    //     const $this = $(currentTarget);
    //
    //     $this
    //       .closest('.p-plan__features-item')
    //       .toggleClass('p-plan__features-item_open')
    //       .siblings('.p-plan__features-item_open')
    //       .removeClass('p-plan__features-item_open');
    //   };
    //
    //   planBlock.on('click', '.p-plan__features-item-header_expand', toggleDescription);
    // }

    // Addons per block
    if (addonsPerBlock.length) {
      const toggleAddon = (e) => {
        const { currentTarget } = e
        const $this = $(currentTarget)
        const currentPrice = $this.data('price')
        const currentAddonId = $this.data('id')
        const isActive = $this.hasClass('p-plan__addons-label_active')

        addonsPerBlock
            .find(`.p-plan__addons-label[data-id=${ currentAddonId }]`)
            .each((index, el) => {
              $(el).toggleClass('p-plan__addons-label_active', !isActive)
            })

        checkPriceModifier()
        updatePrices(getPrices(usersCount, priceModifier))
      }

      addonsPerBlock.on('click', '.p-plan__addons-label', toggleAddon)
    }

    // Observer for sticky bottom filter
    /*
    const priceHeader = document.querySelector('.pricing__content');
    const priceFooter = document.querySelector('.pricing__footer');
    let isHeaderVisible = false;
    let isFooterVisible = false;
    let isOverscroll = false;

    if (priceFooter && window.innerWidth >= 1024) {
      const calculateIntersection = () => {
        if (!isHeaderVisible && !isFooterVisible && !isOverscroll) {
          priceFooter.style.height = `${ priceFooter.offsetHeight }px`;
          priceFooter.classList.add('pricing__footer_fixed');
        } else {
          priceFooter.style.height = null;
          priceFooter.classList.remove('pricing__footer_fixed');
        }
      };

      const handler = (entries) => {
        const headerEntry = entries.find(entry => entry.target.classList.contains('pricing__content'));
        const footerEntry = entries.find(entry => entry.target.classList.contains('pricing__footer'));

        if (headerEntry) {
          isHeaderVisible = headerEntry.isIntersecting;
        }

        if (footerEntry) {
          isFooterVisible = footerEntry.isIntersecting;

          isOverscroll = priceFooter.offsetTop + footerEntry.boundingClientRect.height < window.pageYOffset;
        }

        calculateIntersection();
      };

      let observer = new IntersectionObserver(handler);

      observer.observe(priceHeader);
      observer.observe(priceFooter);

    }
    */
  }

  const run = async () => {
    try {
      const [noUiSlider, getPrices ] = await Promise.all([
        import( /* @vite-ignore */'nouislider'),
        import( /* @vite-ignore */'./_getPrices')
      ])

      init(noUiSlider, getPrices.init)

    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}

/* eslint-enable no-unused-vars */
