export default () => {
  const slickCarousels = document.querySelectorAll('#js-info-gallery, ' +
      '.js-article-slider, ' +
      '#js-testimonials-list' +
      '.js-visuals-gallery' +
      '.js-academy-events, ' +
      '.onboarding__list, ' +
      '.text-slider, ' +
      '.js-products-overview .js-products-overview__slides')
  if (slickCarousels.length < 1) {
    return false
  }

  const init = () => {
    /**
     * info page image slider
     */
    $('#js-info-gallery').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        }
      ]
    })

    /**
     * article image slider
     */
    $('.js-article-slider-nav').slick({
      dots: false,
      arrows: false,
      vertical: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      verticalSwiping: true,
      asNavFor: '.js-article-slider'
    })
    $('.js-article-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      asNavFor: '.js-article-slider-nav',
      responsive: [
        {
          breakpoint: 560,
          settings: {
            dots: true
          }
        }
      ]
    })

    $(window).on('resize orientationchange', function () {
      $('.js-article-slider').slick('resize')
      $('.js-article-slider-nav').slick('resize')
    })

    $('.js-article-slider-nav button[data-slide]').click(function (e) {
      e.preventDefault()
      let slideno = $(this).data('slide')
      $('.js-article-slider').slick('slickGoTo', slideno - 1)
    })

    /**
     * testimonials slider
     */
    $('#js-testimonials-list').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      asNavFor: '#js-testimonials-nav',
      fade: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 560,
          settings: {
            arrows: false
          }
        }
      ]
    })
    $('#js-testimonials-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '#js-testimonials-list',
      dots: false,
      arrows: false,
      focusOnSelect: true
    })

    /**
     * products overview slider
     */
    const productsSliders = $('.js-products-overview')
    productsSliders.each(function () {
      const productsSlider = $(this).find('.js-products-overview__slides')
      const productsSliderNavItems = $(this).find('.js-products-overview__nav > *')
      productsSlider.on('init', function () {
        const dots = productsSlider.find('.slick-dots li')
        productsSliderNavItems.each(function (index) {
          const color = $(this).attr('data-color')
          dots.eq(index).addClass(color)
        })
      })

      productsSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      })

      if (productsSliderNavItems.length) {
        productsSliderNavItems.click(function (e) {
          $(this).addClass('is-active')
          $(this).siblings().removeClass('is-active')
          e.preventDefault()
          let slideIndex = parseInt($(this).index())
          productsSlider.slick('slickGoTo', slideIndex, false)
        })

        productsSlider.on('afterChange', function (event, slick, currentSlide) {
          if (currentSlide < productsSliderNavItems.length) {
            productsSliderNavItems.removeClass('is-active')
            productsSliderNavItems[currentSlide].classList.add('is-active')
          }
        })
      }
    })

    /**
     * interactive landing page image slider
     */
    $('.js-visuals-gallery').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      mobileFirst: true,
      autoplay: true,
      autoplaySpeed: 5000,
      prevArrow: '<button type="button" class="slick-prev slick-arrow"></button>',
      nextArrow: '<button type="button" class="slick-next slick-arrow"></button>',
      responsive: [
        {
          breakpoint: 764,
          settings: {
            arrows: false
          }
        }
      ]
    })

    /**
     * Academy overview events carousel
     */
    const eventsCarousel = $('.js-academy-events')
    eventsCarousel.on('init', function () {
      var captionHeight = 0

      $('.js-academy-events .ov-events__item-caption').each(function () {
        if ($(this).height() > captionHeight) {
          captionHeight = $(this).height()
        }
      })

      $('.js-academy-events .ov-events__item-caption').css('min-height', captionHeight)
    })
    eventsCarousel.slick({
      slidesToShow: 3,
      prevArrow: '<button type="button" class="slick-prev ov-events__btn"></button>',
      nextArrow: '<button type="button" class="slick-next ov-events__btn"></button>',
      // the magic
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false
        }
      }]
    })

    /**
     * testimonials slider
     */
    $('.text-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 560,
          settings: {}
        }
      ]
    })

    /**
     * onboarding slider
     */

    const arrowButton = (buttonPostfix = 'next') => `
  <button
    type="button"
    class="slick-${ buttonPostfix } onboarding__list-btn"
    aria-label="${ buttonPostfix } slide"
    rel="${ buttonPostfix }"
    >
      <svg width="6" height="14" fill="none" viewBox="0 0 6 14">
        <path d="M5.84 6.456L1.44.226C1.23-.076.886-.076.673.225L.16.951c-.212.3-.212.786 0 1.087L3.644 7 .158 11.961c-.211.3-.21.787.001 1.087l.513.727c.213.3.557.3.769 0l4.4-6.23c.212-.301.212-.789 0-1.09z"/>
      </svg>
    </button>
`
    const sliderOptions = {
      infinite: false,
      mobileFirst: true,
      centerPadding: false,
      nextArrow: arrowButton(),
      prevArrow: arrowButton('prev'),
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            variableWidth: true
          }
        },
        {
          breakpoint: 1023,
          settings: 'unslick'
        }
      ]
    }

    let onboardingPlans

    const initSOnboardingSlider = () => {
      onboardingPlans.slick(sliderOptions)
    }

    onboardingPlans = $('.onboarding__list')

    if (onboardingPlans.length) {
      initSOnboardingSlider()
    }


  }

  const run = async () => {
    try {
      await Promise.all([
        import( /* @vite-ignore */'slick-carousel')
      ])

      init()

    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}
