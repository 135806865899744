export default () => {
    const filterBtns = $('#js-team-filter > *')
    const filterItems = $('#js-team-list > *')

    filterBtns.click(function() {
        filterBtns.removeClass('is-active')
        filterBtns.removeClass('text-bold')
        $(this).addClass('is-active')
        $(this).addClass('text-bold')
        const target = $(this).attr('data-target')
        filterItems.each(function(indx, element) {
            if ($(element).attr('data-branch').indexOf(target) < 0) {
                $(element).hide()
            } else {
                $(element).show()
            }
        })
    })
}
