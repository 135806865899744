/* eslint-disable no-unused-vars */

const KVK_SEARCH_LINK = '/signup/organization/search/'
const KVK_GET_PROFILE_LINK = '/signup/organization/profile/'
const csrfTokenName = 'CRAFT_CSRF_TOKEN'
let csrfTokenValue = ''
const getCompanyTitle = (company) => company.name

const KVKSearch = (elementSelector, branchFieldSelector = '', teamSizeFieldSelector = '', property = 'id') => {
  const companyFields = document.querySelectorAll(elementSelector)

  if (companyFields.length < 1) {
    return false
  }

  const run = async (companyField) => {
    try {
      const [lottie] = await Promise.all([
        import( /* @vite-ignore */'jquery-autocomplete')
      ])

      setTimeout(function () {
        csrfTokenValue = $(`[name=${csrfTokenName}]`).val()
      }, 1000)
      let timeout = ''

      $(companyField).autocomplete({
        minLength: 3,
        delay: 200,
        appendMethod: 'replace',
        valid: () => true,
        source: [
          // {
          //   url: `${KVK_SEARCH_LINK}?q=%QUERY%`,
          //   type: 'remote',
          // },
          function (q, add) {
            if (q !== '') {
              if (typeof csrfTokenValue === 'undefined') {
                $.get('/actions/blitz/csrf/token/', (data) => {
                  csrfTokenValue = data
                })
              }

              clearTimeout(timeout)
              timeout = setTimeout(function () {
                $(companyField.closest('.xdsoft_autocomplete')).addClass('loading')
                $.ajax({
                  type: 'POST',
                  url: `${KVK_SEARCH_LINK}?q=${encodeURIComponent(q)}`,
                  data: `${csrfTokenName}=${csrfTokenValue}`,
                  dataType: 'JSON',
                  success: (resp) => {
                    add(resp.data)
                  },
                  error: () => {
                    add([])
                  },
                  complete: () => {
                    $(companyField.closest('.xdsoft_autocomplete')).removeClass('loading')
                  }
                })
              }, 200)
            }
          }
        ],
        getTitle: (company) => getCompanyTitle(company),
        getValue: (company) => getCompanyTitle(company)
      })

    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  companyFields.forEach((companyField) => {
    run(companyField)
  })
}

/*const fillField = ({data}, branchFieldSelector, teamSizeFieldSelector, property) => {
  const branchField = document.querySelectorAll(branchFieldSelector)[0]
  const teamSizeField = document.querySelectorAll(teamSizeFieldSelector)[0]
  const event = document.createEvent('Event')
  event.initEvent('change', false, true)

  branchField.value = data.industry_branch[property]
  teamSizeField.value = data.team_size[property]

  branchField.dispatchEvent(event)
  teamSizeField.dispatchEvent(event)
}*/

export {KVKSearch}

/* eslint-enable no-unused-vars */

