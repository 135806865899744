import $ from 'jquery'
window.$ = window.jQuery = $
import accordion from './_partials/_accordion.jquery'
import tabs from './_partials/_tabs'
import stickyPanels from './_partials/_stickyPanels'
import carousels from './_partials/_carousels'
import priceSlider from './_partials/pricing/_priceSlider'
import range from './_partials/pricing/_priceRangeWidget'
import toggler from './_partials/_toggler'
import classToggler from './_partials/_classToggler'
import validate from './_partials/_validate'
import * as form from './_partials/_forms'
import teamFilter from './_partials/_teamFiter'
import tooltip from './_partials/_tooltip'
import { KVKSearch } from './_partials/forms/KVKCheck'

const init = () => {
  accordion('.js-accordion .accordion__label')
  tabs('#js-testimonials-btns > *', '#js-testimonials > *')
  stickyPanels()
  carousels()
  priceSlider()
  range()
  form.subscribeDemo('#js-subscribe-form')
  form.subscribeDemo('#js-subscribe-home')
  form.subscribeDemo('#js-ebook-download')
  form.subscribeDemo('#js-ebook-download2')
  form.subscribeBlog('#js-blog-subscribe')
  form.changeHubspotCheckboxes('form .js-checkbox')
  form.submitMultipleForms('.js-form')
  form.planFeaturesFilter('.js-features-filter-form')
  form.hsProggresiveFields()
  form.submitDelay()
  form.submitMultipleStepDemoForm()
  toggler('js-toggler')
  classToggler('#js-show-prices', '#testimonials', 'bg-shadow')
  teamFilter()
  tooltip()

  KVKSearch(
      '[data-name="company"]',
      'select[data-name="vertical"]',
      'select[data-name="numer_of_employees_range_"]',
      'title'
  )

  let blacklist = []
  fetch('/signup/blacklist/get_domains', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/text'
    }
  })
      .then(response => response.text())
      .then(data => {
        // Assign response data to the blacklist variable
        blacklist = data

        // Call the validate function
        validate('#user-step-1', blacklist)
        validate('#js-subscribe-form', blacklist)
        validate('#js-subscribe-home', blacklist)
        validate('#js-blog-subscribe', blacklist)
        validate('#js-ebook-download', blacklist)
        validate('#js-ebook-download2', blacklist)
        validate('.js-form', blacklist)
        validate('.js-multiform', blacklist)
        validate('#hubspotForm', blacklist)
      })
      .catch(error => console.error('Error:', error))
}

document.addEventListener('DOMContentLoaded', init, false)
document.addEventListener('htmx:afterRequest', init, false)
