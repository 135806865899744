export default () => {
  function fill(result, i, input) {
    let inpt = $(input)
    if (result.data[input.name] !== undefined && result.data[input.name]) {
      inpt.val([result.data[input.name]])
      return
    }

    if (result.data['email'] === undefined || !!result.data['email'] === false) {
      // console.log('No email in domain request');
      return
    }

    let email = result.data['email']

    if (input.name === 'company_name' || input.name === 'domain') {
      let matches = email.match(/@([^.]*)/)
      let name = matches[1]

      if (['google', 'hotmail', 'gmail'].indexOf(name) !== -1) {
        return
      }

      if (matches !== null) {
        inpt.val([name])
      }
    }
  }

  fetch('/nl/signup/domain_request/get/', {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  })
    .then(response => response.json())
    .then(result => {
      if (result.status === 'success') {
        $('input').each(fill.bind(this, result))
        $('select').each(fill.bind(this, result))

        $('form')
          .trigger('checkValidation')
          .trigger('refill') //checking domain
      }
    })
}
