/* eslint-disable camelcase */
export const initMicrosoftAuth = () => {
    const msalConfig = {
        auth: {
            clientId: '34048725-7ee2-427d-90d3-eca5210f9fd2',
            authority: 'https://login.microsoftonline.com/common',
            redirectUri: window.location
        },
        cache: {
            cacheLocation: 'sessionStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        }
    }

    const sendDataOnSignIn = (getMicrosoftData, url = 'signup/domain_request') => {
        // signup/domain_request/
        const dataObject = {}
        const [microsoft_id, first_name, last_name, email] = getMicrosoftData
        dataObject[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue // Append CSRF Token
        dataObject['microsoft_id'] = microsoft_id
        dataObject['first_name'] = first_name
        dataObject['last_name'] = last_name
        dataObject['email'] = email
        dataObject['conditions_accept'] = true

        fetch(`/${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObject)
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                    window.location.href = '/nl/signup/setup-domain/'
                }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error('Error:', error)
            })
    }

    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    const loginRequest = {
        scopes: ['openid', 'profile', 'User.Read']
    }

    // Create the main myMSALObj instance
    // configuration parameters are located at authConfig.js
    // eslint-disable-next-line no-undef
    const myMSALObj = new Msal.UserAgentApplication(msalConfig)

    function signIn() {
        myMSALObj
            .loginPopup(loginRequest)
            .then(() => {
                let account = myMSALObj.getAccount()

                if (account) {
                    let name = account.name.split(' ')

                    sendDataOnSignIn([account.accountIdentifier, name[0] ?? '', name[1] ?? '', account.userName])
                }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error)
            })
    }

    $('#microsoftSignIn').on('click', signIn)
}
