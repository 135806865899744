export default togglers => {
  $(togglers).click(function(e) {
    e.preventDefault()
    e.stopPropagation()

    // $(togglers).removeClass('is-active');
    const section = $(this).parent()
    const sections = $(this)
      .parent()
      .siblings()
    sections.removeClass('is-active')

    if (section.hasClass('is-active')) {
      section.removeClass('is-active')
    } else {
      section.addClass('is-active')
    }
  })
}
