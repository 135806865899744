import { toggleVisibility } from './forms/toggleVisibility'

const formDisableDuration = 5000

const submitMultipleForms = formsSelectors => {
  const forms = $(formsSelectors)

  if (!forms.length) {
    return false
  }

  forms.each(function() {
    let step = 0
    $(this).submit(function(ev) {
      ev.preventDefault()
      const form = $(ev.currentTarget)
      const redirectTarget = form.attr('data-target')
      const submitBtn = form.find('button')
      const validationState = form.valid()

      if (!validationState) {
        return false
      }

      if (step === 0 && form.find('[name="fields[currentFormName]"]')
        && form.find('[name="fields[currentFormName]"]').val() === 'Plan een demo (KVK)') {
        const industry = $('[data-name="vertical"]').val()

        form.addClass('is-loading')
        submitBtn.attr('disabled', 'disabled')

        $('.ajax_form').load(`/nl/signup/challenges_form?industry=${encodeURI(industry)}`, () => {
          step++
          $('.ajax_form--toggle').fadeOut(100)
          setTimeout(() => {
            $('.ajax_form').fadeIn(100)
            toggleVisibility()

            form.removeClass('is-loading')
            submitBtn.attr('disabled', false).text('Aanvraag afronden')
          }, 100)
        })

        return false
      }

      form.addClass('is-loading')
      submitBtn.attr('disabled', 'disabled')

      $.post({
        url: '/',
        dataType: 'json',
        data: $(this).serialize(),
        success: function(response) {
          if (response.success || response.message === 'Entry saved.') {
            if (redirectTarget) {
              const lastCh = location.pathname.slice(-1)
              const delimiter = lastCh !== '/' ? '/' : ''
              location.href = location.pathname + delimiter + redirectTarget
            } else {
              setTimeout(() => {
                form.find('.js-thanks').removeClass('is-hidden')
                submitBtn.attr('disabled', false)
                form.removeClass('is-loading')
              }, formDisableDuration)
            }
            form.addClass('is-submitted')

            setTimeout(function() {
              form.find('.field input, .field textarea').val('')
            }, 3600)
          } else {
            // console.log(response);
          }
        }
      })
    })
  })
}

const subscribeDemo = formId => {
  const form = $(formId)

  form.submit(function() {
    const submitBtn = form.children('button')
    if (!$(this).valid()) {
      return
    }
    $(formId).addClass('is-loading')
    submitBtn.attr('disabled', true)
  })
}

const subscribeBlog = formId => {
  const form = $(formId)
  const submitBtn = form.children('button')

  form.submit(function(ev) {
    ev.preventDefault()
    if (!$(this).valid()) {
      return
    }

    form.addClass('is-loading')
    submitBtn.attr('disabled', true)

    $.post({
      url: '/',
      dataType: 'json',
      data: $(this).serialize(),
      success: function(response) {
        if (response.success || response.message === 'Entry saved.') {
          setTimeout(() => {
            form.find('.js-lead').addClass('is-hidden')
            form.find('.js-thanks').removeClass('is-hidden')
            submitBtn.attr('disabled', false)
            form.removeClass('is-loading')
          }, formDisableDuration)

          setTimeout(function() {
            form.find('.field input, .field textarea').val('')
          }, 3600)

          // setTimeout(function() {
          //   form.find('.js-lead').removeClass('is-hidden');
          //   form.find('.js-thanks').addClass('is-hidden');
          // }, 10000);
        } else {
          // console.log(response);
        }
      }
    })
  })
}

const changeHubspotCheckboxes = selector => {
  const checkboxes = $(selector)

  if (!checkboxes) {
    return false
  }

  checkboxes.change(function(e) {
    // console.log('change');
    const el = e.currentTarget
    switch (el.value) {
      case 'true':
        el.value = false
        break
      case 'false':
        el.value = true
    }
  })
}

const planFeaturesFilter = () => {
  const forms = $('.js-features-filter')
  if (!forms.length) {
    return false
  }

  forms.each(function() {
    const form = $(this)
    const select = $(this)
      .find('select')
      .first()
    if (!select.length) {
      return false
    }

    const toggleFeatures = planClassName => {
      const features = $('.js-package-feature')
      features.removeClass('is-hidden')

      features.each(function() {
        const feature = $(this)
        if (!this.classList.contains(planClassName)) {
          feature.addClass('is-hidden')
        }
      })
    }

    const initPlanClassName = $(select).val()

    toggleFeatures(initPlanClassName)

    form.submit(function(ev) {
      ev.preventDefault()
    })

    select.on('change', function() {
      const currPlanClassName = this.value
      toggleFeatures(currPlanClassName)
    })
  })
}

const hsProggresiveFields = () => {
  // console.log('hsProggresiveFields');
  const forms = document.querySelectorAll('form')

  if (!forms.length) {
    return false
  }

  for (const form of forms) {
    const parentFields = form.querySelectorAll('.js-dependant-parent-field')
    const childFieldGroups = form.querySelectorAll('.js-dependant-child-field')

    if (!parentFields.length || !childFieldGroups.length) {
      return false
    }

    const checkFields = parentField => {
      const parentFildName = parentField.dataset.name
      const parentFildValue = parentField.value
      const parentFildTag = parentField.tagName
      const parentFildType = parentField.type

      // console.log('parentFildTag', parentFildTag);
      // console.log('parentFildType', parentFildType);
      // console.log('parentFildValue', parentFildValue);

      const currChildGroups = Array.from(childFieldGroups).filter(item => {
        return item.dataset.dependandFrom === parentFildName
      })

      for (const childGroup of currChildGroups) {
        const groupFilters = childGroup.dataset.dependantFilters

        // console.log('groupFilters', groupFilters);

        const isJsonString = str => {
          try {
            JSON.parse(str)
          } catch (e) {
            return false
          }
          return true
        }

        if (!isJsonString(groupFilters)) {
          return false
        }

        const filters = JSON.parse(groupFilters)

        // console.log(filters, filters);

        for (const filter of filters) {
          const operator = filter.operator
          const strValues = filter['strValues']
          const strValue = filter['strValue']
          const numValue = parseInt(filter['numberValue'])
          const numValues = filter['numberValues']
          let result = false

          const toggleChildGroup = result => {
            if (result === true) {
              childGroup.classList.remove('is-hidden')
            } else {
              childGroup.classList.add('is-hidden')
            }
          }
          // console.log('numValue', numValue, typeof numValue);
          // console.log('numValues', numValues[0], numValues[1]);
          // console.log('parentFildValue', parentFildValue, typeof parentFildValue);

          if (parentFildTag === 'INPUT' && parentFildType === 'number') {
            switch (operator) {
              case 'EQ':
                result = parseInt(parentFildValue) === numValue
                break

              case 'NEQ':
                result = parseInt(parentFildValue) !== numValue
                break

              case 'LT':
                result = parseInt(parentFildValue) < numValue
                break

              case 'LTE':
                result = parseInt(parentFildValue) <= numValue
                break

              case 'GT':
                result = parseInt(parentFildValue) > numValue
                break

              case 'GTE':
                result = parseInt(parentFildValue) >= numValue
                break

              case 'BETWEEN':
                result = parseInt(parentFildValue) >= numValues[0] && parseInt(parentFildValue) <= numValues[1]
                break

              case 'NOT_BETWEEN':
                result = parseInt(parentFildValue) < numValues[0] && parseInt(parentFildValue) > numValues[1]
                break

              case 'IS_NOT_EMPTY':
                result = parentFildValue !== ''
                break
            }
          }

          if (
            parentFildTag === 'TEXTAREA' ||
            (parentFildTag === 'INPUT' && (parentFildType === 'text' || parentFildType === 'email'))
          ) {
            switch (operator) {
              case 'EQ':
                result = strValue === parentFildValue.trim()
                break

              case 'NEQ':
                result = strValue !== parentFildValue.trim()
                break

              case 'CONTAINS':
                result = parentFildValue.indexOf(strValue) !== -1
                break

              case 'DOESNT_CONTAIN':
                result = parentFildValue.indexOf(strValue) === -1
                break

              case 'STR_STARTS_WITH':
                result = parentFildValue.indexOf(strValue) === 0
                break

              case 'STR_ENDS_WITH':
                result = parentFildValue.indexOf(strValue, parentFildValue.length - strValue.length) !== -1
                break

              case 'IS_NOT_EMPTY':
                result = parentFildValue !== ''
                break
            }
          }

          if (parentFildTag === 'INPUT' && parentFildType === 'checkbox') {
            switch (operator) {
              case 'EQ':
                result = strValues.indexOf(parentFildValue) !== -1
                break

              case 'IS_NOT_EMPTY':
                result = parentFildValue !== ''
                break
            }
          }

          if (parentFildTag === 'SELECT' || (parentFildTag === 'INPUT' && parentFildType === 'radio')) {
            switch (operator) {
              case 'SET_ANY':
                result = strValues.includes(parentFildValue)
                break

              case 'SET_NOT_ANY':
                result = !strValues.includes(parentFildValue)
                break

              case 'IS_NOT_EMPTY':
                result = parentFildValue !== ''
                break
            }
          }

          // console.log('result', result);
          toggleChildGroup(result)
        }
      }
    }

    for (const parentField of parentFields) {
      checkFields(parentField)

      parentField.addEventListener('change', e => {
        // console.log('fld changed');
        checkFields(e.currentTarget)
      })
    }
  }
}

const submitDelay = () => {
  //avoid random title cache
  let title = $('input[name="title"]')
  title.val(title.val() + Date.now().toString())

  //add timeout after submit
  $('form').on('submit', function() {
    $('#submbtn').attr('disabled', 'disabled')

    setTimeout(function() {
      $('#submbtn').attr('disabled', null)
    }, formDisableDuration)
  })
}

const submitMultipleStepDemoForm = () => {
  // const content = $('.js-multiform-content')
  const form = $('.js-multiform-content .js-multiform')
  const stepsWidget = $('.js-multiform-content .js-multiform-steps')
  // const successBanner = $('.js-multiform-banner')
  const backBtn = $('.js-multiform-content .js-multiform-back')
  const termsField = $('.js-multiform-content .js-multiform .js-terms')
  let step = 1
  let demoBaseUrl = location.href
  const demoTitle = document.title

  if(demoBaseUrl.substr(-1) === '/') {
    demoBaseUrl = demoBaseUrl.substr(0, demoBaseUrl.length - 1)
  }

  if (!form) {
    return false
  }

  const formPages = form.find('.js-form-page')
  const nextStepFields = form.find('.js-next-form-page input', '.js-next-form-page select', '.js-next-form-page textarea')

  nextStepFields.attr('disabled', true)

  const changeProgress = (step) => {
    const currentStep = $('.js-steps-current')
    const currentProgress = $('.js-steps-progress')

    formPages.toggleClass('hidden')
    nextStepFields.removeAttr('disabled')

    if (currentStep && currentProgress) {
      const total = stepsWidget.data('total')
      currentStep.text(step)
      currentProgress.width((step * 100 / total) + '%')

      if (step > 1) {
        backBtn.removeClass('hidden')
      } else {
        backBtn.addClass('hidden')
      }

      if (step === 1) {
        changeUrl('', demoTitle)
      }

      if (step === 2) {
        changeUrl('extra', 'Je bent er bijna!')
      }
    }

    if (step === 1) {
      termsField.removeClass('hidden')
    } else {
      termsField.addClass('hidden')
    }
  }

  const changeUrl = (stepSlug='', stepTitle='Demo') => {
    const nextURL = demoBaseUrl + (stepSlug ? ('/' + stepSlug) : '')
    const nextTitle = stepTitle
    const nextState = { additionalInformation: 'Updated the URL with JS' }

    // This will create a new entry in the browser's history, without reloading
    window.history.pushState(nextState, nextTitle, nextURL)

    // This will create a new entry in the browser's history, without reloading
    window.history.pushState(nextState, nextTitle, nextURL)

    if (typeof dataLayer === 'object') {
      // eslint-disable-next-line no-undef
      dataLayer.push({
        event : 'virtualPageView',  //fixed value you need to use as trigger in GTM
        virtualPagePath : nextURL,
        virtualPageTitle: nextTitle
      })
    }

    // send GA pageView
    if (typeof ga === 'function') {
      // eslint-disable-next-line no-undef
      ga('send', {
        hitType: 'pageview',
        page: nextURL
      })

      // eslint-disable-next-line
      // console.log('ga', ga, nextURL);
    }
  }

  const showSuccessBanner = () => {
    location.href = '/nl/plan-een-demo/bedankt'
  }

  backBtn.click(() => {
    step--
    changeProgress(step)
  })

  form.submit(function(ev) {
    ev.preventDefault()
    const form = $(ev.currentTarget)
    let validationState = form.valid()

    const submitForm = () => {
      $.post({
        url: '/',
        dataType: 'json',
        data: $(this).serialize(),
        success: function(response) {
          if (response.success || response.message === 'Entry saved.') {
            form.data('submitted', true)
            // form.addClass('hidden')
            showSuccessBanner()

            setTimeout(function() {
              form.find('.field input, .field textarea').val('')
            }, 3600)
          } else {
            // console.log(response);
          }
        }
      })
    }

    if (!validationState) {
      return false
    }

    if (validationState && formPages.length > 1) {
      if (step === 2) {
        if (form.data('submitted')) {
          form.addClass('hidden')
          showSuccessBanner()
        } else {
          submitForm()
        }
      }
      if (step === 1) {
        step++
        changeProgress(step)
      }
    }

    // submitBtn.attr('disabled', 'disabled')

  })

}

export {
  subscribeDemo,
  subscribeBlog,
  changeHubspotCheckboxes,
  submitMultipleForms,
  planFeaturesFilter,
  hsProggresiveFields,
  submitDelay,
  submitMultipleStepDemoForm
}
