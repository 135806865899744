export default (formId, url, method = 'POST') => {
  const form = document.getElementById(formId)
  if (!form) {
    return
  }

  form.addEventListener('submit', evt => {
    const submitButton = $(form).find('button')
    evt.preventDefault()

    if ($(form).valid() === false) {
      // console.log('Form is invalid');
      return
    }

    $(form).addClass('is-loading subscribe-form')
    submitButton.attr('disabled', 'disabled')

    let options = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }

    if (method !== 'GET') {
      options.body = $(form).serialize()
      options.body += `&${window.Craft.csrfTokenName}=${window.Craft.csrfTokenValue}`
    }

    fetch(`/${url}`, options)
      .then(response => response.json())
      .then(result => {
        $(form).removeClass('is-loading subscribe-form')
        submitButton.removeAttr('disabled')

        if (result.status === 'success') {
          if (form.hasAttribute('data-go-to-step')) {
            const step = form.dataset.goToStep
            window.location.href = `/nl/signup/${step}/`
          }
        } else {
          // console.log('Error:', result);
        }
      })
  })
}
