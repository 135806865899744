export default /**
 *
 */
() => {
    const body = $('body')
    const pageHeader = $('#js-header-main')
    const isScrollableHeader = !pageHeader.hasClass('disable-sticky-nav') && !body.hasClass('disable-sticky-nav')
    const isScrollableTransparentHeader = body.hasClass('transparent-header-page')

    const table = $('#js-features-table')
    const tableHeader = $('#js-table-header')
    const stickyHeading = $('#js-table-header .js-heading')
    const subTable = $('#js-sub')
    const tableFooter = $('#js-table-footer')
    const subItemss = $('#js-features-table .js-sub-item')

    const prodNav = $('#js-products-nav')
    const prodNavLinks = $('#js-products-nav .list a')
    const prodList = $('#js-products-list')

    const blogAsideSearch = $('#js-blogside-search')
    const blogAsideBook = $('#js-blogaside-book')
    const blogAside = $('#js-blog-aside')
    const blogPagination = $('#js-blog-pagination')

    const nav = $('#js-nav')
    const main = $('#js-main')

    const productHero = $('#js-product-hero')
    const productNav = $('#js-product-nav')
    const siblingsPoductNav = $('#js-sibl-product-nav')

    if (!main.length) {
        return
    }

    const toggleHeader = () => {
        if (subTable.length) {
            if (window.pageYOffset > subTable.offset().top) {
                table.addClass('is-active')
                tableHeader.addClass('is-active')
            } else {
                table.removeClass('is-active')
                tableHeader.removeClass('is-active')
            }

            if (window.pageYOffset > tableFooter.offset().top) {
                table.removeClass('is-active')
                tableHeader.removeClass('is-active')
            }
        }
    }

    const toggleSubHeaders = () => {
        subItemss.each(function() {
            const subTable2 = $(this)
            if (subTable2.length) {
                const subHeader = $(this).find('.js-subheading')

                if (window.pageYOffset > subTable2.offset().top) {
                    if (stickyHeading.text() !== subHeader.text()) {
                        stickyHeading.text(subHeader.text())
                        stickyHeading.attr('data-class', subHeader.attr('data-class'))
                    }
                }

                if (window.pageYOffset > tableFooter.offset().top) {
                    subHeader.removeClass('is-active')
                }
            }
        })
    }

    const switchNavItem = (el) => {
        const targetId = el.attr('href')
        const target = $(targetId)
        if (target.length) {
            if (window.pageYOffset > target.offset().top - 180) {
                if (!el.hasClass('is-active')) {
                    prodNav.find('a').removeClass('is-active')
                    el.addClass('is-active')
                }
            }
        }
    }

    const scroll = () => {
        prodNavLinks.on('click', (el) => {
            el.preventDefault()
            const $this = $(el.currentTarget)
            const targetId = $this.attr('href')
            window.location.hash = targetId

            const target = $(targetId)
            if (target.length) {
                $([document.documentElement, document.body]).animate(
                    {
                        scrollTop: target.offset().top
                    },
                    100
                )
            }
        })
    }
    scroll()

    /**
     * main nav
     */
    if ($(window).scrollTop() > (main.offset().top + 80) && isScrollableHeader) {
        nav.addClass('is-sticky')
        nav.addClass('is-sticky-cc')

        if (!body.hasClass('overflow-nav')) {
            body.css('padding-top', nav.outerHeight())
        }
    }

    /**
     * aside nav on 'Functies' page
     */
    if (prodList.length && window.pageYOffset > prodList.offset().top - 180) {
        productNav.addClass('is-sticky')
    }

    window.addEventListener(
        'scroll',
        function() {
            /**
             * main nav
             */
            if (isScrollableHeader) {
                if (window.pageYOffset > main.offset().top) {
                    nav.addClass('is-sticky')
                    nav.addClass('is-sticky-cc')

                    if (!body.hasClass('overflow-nav')) {
                        body.css('padding-top', nav.outerHeight())
                    }
                } else {
                    nav.removeClass('is-sticky')
                    nav.removeClass('is-sticky-cc')

                    if (!body.hasClass('overflow-nav')) {
                        body.css('padding-top', 0)
                    }
                }
            }

            if (isScrollableTransparentHeader && isScrollableHeader) {
                if (window.pageYOffset > nav.outerHeight()) {
                    nav.addClass('is-sticky')
                } else {
                    nav.removeClass('is-sticky')
                }
            }

            /**
             * aside sticky nav on 'functies' page
             */
            if ($(window).width() >= 1248) {
                if (prodList.length) {
                    const stopNavPos = prodList.offset().top + prodList.height() - prodNav.height() - 240

                    if (window.pageYOffset > prodList.offset().top - 180) {
                        prodNav.addClass('is-sticky')
                    } else {
                        prodNav.removeClass('is-sticky')
                    }

                    if (window.pageYOffset > prodList.offset().top - 100) {
                        prodNavLinks.each(function() {
                            switchNavItem($(this))
                        })

                        if (window.pageYOffset > stopNavPos) {
                            prodNav.removeClass('is-sticky')
                            prodNav.addClass('is-sticky-bottom')
                        } else {
                            prodNav.addClass('is-sticky')
                            prodNav.removeClass('is-sticky-bottom')
                        }
                    }
                }
            }

            /**
             * table nav on 'tarieven' page
             */
            if (table.length) {
                toggleHeader()
                toggleSubHeaders()

                if (window.pageYOffset > table.offset().top) {
                    nav.hide()
                } else {
                    nav.show()
                }

                if (window.pageYOffset > table.offset().top + table.height()) {
                    nav.show()
                }
            }

            if ($(window).width() >= 1024) {
                if (blogAside.length && blogAsideSearch.length && blogAsideBook.length) {
                    const blogPaginationHeight = blogPagination.outerHeight()

                    if (window.pageYOffset > blogAsideSearch.offset().top + blogAsideSearch.outerHeight() - 70) {
                        blogAsideBook.addClass('is-sticky')

                        if (window.pageYOffset > blogAside.outerHeight() - 70) {
                            blogAsideBook.addClass('is-sticky-bottom')
                            blogAsideBook.css('bottom', blogPaginationHeight)
                        } else {
                            blogAsideBook.removeClass('is-sticky-bottom')
                        }
                    } else {
                        blogAsideBook.removeClass('is-sticky')
                    }
                }
            }

            /**
             * siblings products subnav on 'functie detail' age
             */
            if (window.pageYOffset > 60 && isScrollableHeader) {
                pageHeader.addClass('is-white')
            } else {
                pageHeader.removeClass('is-white')
            }
            if ($(window).width() >= 1248) {
                if (productHero.length && siblingsPoductNav.length) {
                    const heroHeight = productHero.offset().top + productHero.outerHeight() + pageHeader.outerHeight()
                    if (window.pageYOffset > heroHeight - 60) {
                        productHero.css('margin-bottom', siblingsPoductNav.outerHeight() + 'px')
                    }
                    if (window.pageYOffset < heroHeight - 60) {
                        productHero.css('margin-bottom', '')
                    }
                }
            } else {
                if (siblingsPoductNav.length) {
                    if (window.pageYOffset > 100) {
                        siblingsPoductNav.addClass('is-fixed')
                    }
                    if (window.pageYOffset < 100 && siblingsPoductNav.hasClass('is-fixed')) {
                        siblingsPoductNav.removeClass('is-fixed')
                    }
                }
            }
        },
        { passive: true }
    )
}
