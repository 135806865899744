/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
const googleUser = {}

const sendDataOnSignIn = (getGoogleData, url = 'signup/domain_request') => {
  // signup/domain_request/
  const dataObject = {}
  const [google_id, first_name, last_name, email] = getGoogleData
  dataObject[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue // Append CSRF Token
  dataObject['google_id'] = google_id
  dataObject['first_name'] = first_name
  dataObject['last_name'] = last_name
  dataObject['email'] = email
  dataObject['conditions_accept'] = true

  fetch(`/${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataObject)
  })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          window.location.href = '/nl/signup/setup-domain/'
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error:', error)
      })
}

const getUserDataFromGoogle = (googleUser) => {
  //const profile = googleUser.getBasicProfile();
  const google_id = googleUser.sub //profile.getId();
  const first_name = googleUser.given_name //profile.getGivenName();
  const last_name = googleUser.family_name //profile.getFamilyName();
  const email = googleUser.email //profile.getEmail();
  // console.log(first_name, email, last_name, google_id);
  return [google_id, first_name, last_name, email]
}

const handleGoogleResponse = (response) => {
  const jwtParse = function (token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
  }
  const payload = jwtParse(response.credential)
  //const googleBtn = document.getElementById('google-auth-custom');
  // const googleBtnText = googleBtn.querySelector('.google-auth__text');

  //googleBtnText.textContent = 'Signed in: ' + payload.given_name;

  sendDataOnSignIn(getUserDataFromGoogle(payload))
}
// eslint-disable-next-line no-unused-vars
const initGoogleAuth = () => {
  // Retrieve the singleton for the GoogleAuth library and set up the client.
  // eslint-disable-next-line no-undef
  google.accounts.id.initialize({
    client_id: '56568939871-4es6j58su5977gsnqcuvtg0jvm0vcuol.apps.googleusercontent.com',
    callback: function (response) {
      const jwtParse = function (token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )

        return JSON.parse(jsonPayload)
      }
      const payload = jwtParse(response.credential)
      //const googleBtn = document.getElementById('google-auth-custom');
      //const googleBtnText = googleBtn.querySelector('.google-auth__text');

      //googleBtnText.textContent = 'Signed in: ' + payload.given_name;

      sendDataOnSignIn(getUserDataFromGoogle(payload))
    }
  })

  const conditions = document.getElementById('policy')
  const gapiBtn = document.querySelector('.g_id_signin')

  //attachSignin(document.getElementById('google-auth-custom'));

  const checkConditions = () => {
    if (!!conditions.checked === false) {
      $('label[for="policy"]').click()
    }
  }
  gapiBtn.addEventListener('click', checkConditions)
}

export {initGoogleAuth, handleGoogleResponse}
