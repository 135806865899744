import SlimSelect from 'slim-select'
import * as Cookies from 'js-cookie'

export default () => {
  const selects = document.querySelectorAll('.js-slim-select')

  selects.forEach((item) => {
    const saveInCookie = item.dataset.save
    const firstOption = item.querySelector('option')
    if (firstOption) {
      firstOption.setAttribute('data-placeholder', 'true')
    }

    const slimSelect = new SlimSelect({
      select: item,
      showSearch: false,
      onChange: (option) => {
        item.dispatchEvent(new Event('blur'))
        item.classList.add('is-selected')
        const field = item.closest('.field')
        if (field !== null) {
          field.classList.remove('field-error')
        }
        if (saveInCookie) {
          Cookies.set(item.name + '_value', option.value, { expires: 365 })
        }
      }
    })
    item.removeAttribute('style')
    const savedValue = Cookies.get(item.name + '_value')

    if (!saveInCookie || !savedValue) {
      return
    }
    slimSelect.setSelected(Cookies.get(item.name + '_value'))
  })
}
