export default () => {
  const addressField = document.querySelector('.authorization__subtitle strong')
  if (!addressField) {
    return
  }

  fetch('/nl/signup/domain_request/get/', {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          addressField.textContent = result.data.email
        } else {
          // console.log('Error:', result);
        }
      })
}
