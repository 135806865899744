export default () => {
    let checking = false
    let checked = false

    const checkEnvironmentCreationStatus = (timerId) => {
        checking = true
        fetch('/nl/signup/domain_request/check/', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        })
            .then((response) => response.json())
            .then((result) => {
                if (checked === true) {
                    clearInterval(timerId)

                    return
                }
                if (result.status === 'success') {
                    if (typeof dataLayer === 'object') {
                      // eslint-disable-next-line no-undef
                      dataLayer.push({
                        event: 'submit_trial_form'
                      })
                    }
                    //see goToDomain in templates/_layouts/layout-signup-step-6.twig
                    window.signupUrl = 'https://' + result.data.domain + '.' + result.data.serverName + '/site/jwt'
                    window.signupToken = result.data.token

                    let progress = document.querySelector('.progress-status')
                    let notComplete = progress.querySelector('.not-complete')
                    let complete = progress.querySelector('.is-complete')
                    $('#skip-style').html('')
                    notComplete.classList.add('hidden')
                    complete.classList.remove('hidden')

                    checked = true
                    // remove 3s fetch interval
                    clearInterval(timerId)
                } else {
                    // console.log('Error:', result);
                }
                checking = false
            })
    }

    const createEnvironment = function() {
        let data = {}
        data[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue

        fetch('/nl/signup/domain_request/submit/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(() => {
            const timerId = setInterval(() => {
                if (checking === false) {
                    checkEnvironmentCreationStatus(timerId)
                }
            }, 3000)
        })
    }

    createEnvironment()
}
