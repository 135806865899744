export default (() => {
  const pricingSection = document.querySelector(('#js-pricing-plans'))
  if (pricingSection === null) {
    return false
  }

  const run = async () => {
    try {
      await Promise.all([
        import( /* @vite-ignore */'slick-carousel')
      ])

      let plansBlock

      const sliderOptions = {
        centerMode: true,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        centerPadding: '30px',
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              variableWidth: true,
              centerPadding: false
            }
          },
          {
            breakpoint: 1023,
            settings: 'unslick'
          }
        ]
      }

      const handleNavClick = (e) => {
        const { currentTarget } = e
        const direction = currentTarget.getAttribute('rel')

        plansBlock.slick(`slick${ direction[0].toUpperCase() }${ direction.slice(1) }`)
      }

      const events = () => {
        plansBlock.on('click', '.p-plan__nav-btn', handleNavClick)
      }

      const initSlider = () => {
        plansBlock.slick(sliderOptions)

        events()
      }

      const init = () => {
        plansBlock = $('.plans')

        if (plansBlock.length && window.innerWidth < 1024) {
          initSlider()
        }
      }

      init()

    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }
  run()
})
