import tippy from 'tippy.js'
import 'tippy.js/themes/light.css'

export default () => {
  let placement = 'top'

  if (window.innerWidth > 960) {
    placement = 'right'
  }

  tippy('[data-tippy-content]', {
    trigger: 'mouseenter focus click',
    placement: placement,
    maxWidth: 240
  })
}
