const toggleVisibility = () => {
  const elem = document.getElementsByClassName('toggle-visibility')[0]
  const targetClass = elem.dataset.target
  const target = document.getElementsByClassName(targetClass)[0]

  elem.addEventListener('change', () => {
    if (elem.checked) {
      target.style.display = 'block'
      target.focus()
    } else {
      target.value = ''
      target.style.display = 'none'
    }
  })
}

export { toggleVisibility }