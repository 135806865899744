/* eslint-disable camelcase */
import 'jquery-validation'

export default (formId) => {

    let blacklist = []
    fetch('/signup/blacklist/get_domains', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/text'
      }
    })
        .then(response => response.text())
        .then(data => blacklist = data)
        .catch(error => console.error('Error:', error))

    $.validator.addMethod(
        'userMail',
        function (value) {
            return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,12}$/.test(value)
        },
        'Vul alstublieft een geldig e-mailadres in'
    )
    $.validator.addMethod(
        'companyMail',
        function (value) {
            let domainMatch = value.match(/@(.*)/)

            if (domainMatch === null) {
                return true
            }

            let domain = domainMatch[1]

            return blacklist === [] || blacklist.indexOf(domain) === -1
        },
        'Vul een zakelijk e-mailadres in'
    )
    $.validator.addMethod(
        'userPassword',
        function (value) {
            return /^(?=.*[a-zA-Z])(?=\w*[0-9])$/.test(value)
        },
        'Wachtwoord bevat alleen letters en cijfers'
    )
    $.validator.addMethod(
        'domainSpecialChars',
        function (value) {
            return /^[\w0-9]+$/i.test(value)
        },
        'Het domein mag alleen letters en cijfers bevatten.'
    )
    $.validator.addMethod(
        'domainName',
        function (value) {
            return /^[a-zA-Z0-9]+$/i.test(value)
        },
        'Domein bevat alleen letters en cijfers.'
    )

    $.validator.addMethod(
        'domainSpaces',
        function (value) {
            return /^[\S]+$/i.test(value)
        },
        'Het domein mag geen spaties bevatten.'
    )

    //Prevent form submission before loading JS
    let submitBtn = $('button[type=submit]')
    submitBtn.attr('disabled', null)

    submitBtn = $(formId).find('button[type=submit]')

    function removeDisabledFromSubmit(formId) {
        let statusInput = $('#status')
        let status = statusInput.length === 0 || statusInput.val() === '1'

        if ($(formId).validate().checkForm() && status) {
            submitBtn.removeClass('auth-btn--disabled').addClass('auth-btn--green')
        } else {
            submitBtn.removeClass('auth-btn--green').addClass('auth-btn--disabled')
        }
    }

    $('input').on('input', function () {
        removeDisabledFromSubmit(formId)
    })

    $('input[type="hidden"]').on('click', function () {
        removeDisabledFromSubmit(formId)
    })

    $(formId).on('checkValidation', function () {
        removeDisabledFromSubmit(formId)
    })

    let validator = $(formId).validate({
        focusCleanup: true,
        onkeyup: false,
        errorClass: 'validation-error-text',
        rules: {
            email: {
                email: false,
                required: true,
                minlength: 3,
                userMail: true,
                companyMail: true
            },
            conditions_accept: {
                required: true
            },
            first_name: {
                required: true,
                minlength: 2
            },
            last_name: {
                required: true,
                minlength: 2
            },
            password: {
                required: true,
                minlength: 8
            },
            company_name: {
                required: true,
                minlength: 3
            },
            domain: {
                required: true,
                minlength: 3,
                maxlength: 25,
                domainSpaces: true,
                domainSpecialChars: true,
                domainName: true
            },
            phone: {
                minlength: 10,
                digits: true,
                required: true
            },
            branch: {
                required: true
            },
            team_size: {
                required: true
            },
            source: {
                required: true
            }
        },
        messages: {
            email: {
                email: 'Vul hier een geldig e-mailadres in.',
                required: 'Dit veld is verplicht',
                minlength: $.validator.format('Vul hier minimaal {0} tekens in.'),
                companyMail: 'Vul een zakelijk e-mailadres in'
            },
            conditions_accept: {
                required: 'Dit veld is verplicht'
            },
            first_name: {
                required: 'Dit veld is verplicht',
                minlength: $.validator.format('Vul hier minimaal {0} tekens in.')
            },
            last_name: {
                required: 'Dit veld is verplicht',
                minlength: $.validator.format('Vul hier minimaal {0} tekens in.')
            },
            password: {
                required: 'Dit veld is verplicht',
                userPassword: true,
                minlength: $.validator.format('Vul hier minimaal {0} tekens in.')
            },
            company_name: {
                required: 'Dit veld is verplicht',
                minlength: $.validator.format('Vul hier minimaal {0} tekens in.')
            },
            domain: {
                required: 'Dit veld is verplicht',
                minlength: $.validator.format('Vul hier minimaal {0} tekens in.'),
                maxlength: $.validator.format('Gebruik niet meer dan {0} characters...')
            },
            phone: {
                minlength: 'Telefoonnummer is te kort.',
                digits: 'Vul een geldig telefoonnummer in.',
                required: 'Dit veld is verplicht'
            },
            branch: {
                required: 'Dit veld is verplicht'
            },
            team_size: {
                required: 'Dit veld is verplicht'
            },
            source: {
                required: 'Dit veld is verplicht'
            }
        },
        errorPlacement: function (error, element) {
            error.appendTo(element.closest('.authorization__form-item').find('.error-text'))
        },
        highlight: function (element) {
            $(element).closest('.authorization__form-item').addClass('error')
        },
        unhighlight: function (element) {
            $(element).closest('.authorization__form-item').removeClass('error')
        }
    })

    //Select need to be checked manual
    $('select')
        .on('change', function () {
            removeDisabledFromSubmit(formId)
            validator.element(this)
        })
        .on('blur', function () {
            validator.element(this)
        })

    //After refill checking form
    //Valid ? make submit green : hide errors
    $(formId).on('refill', function () {
        if ($(formId).valid() === false) {
            validator.resetForm()
        }
    })
}
