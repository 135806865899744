export default (tabButtons, tabSections) => {
  const tabs = $(tabButtons)
  const sections = $(tabSections)

  tabs.click(function(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!$(this).hasClass('is-active')) {
      const target = $(this).attr('data-id')

      tabs.removeClass('is-active')
      sections.removeClass('is-visible')

      $(this).addClass('is-active')
      $('#' + target).addClass('is-visible')
    }
  })

  return false
}
